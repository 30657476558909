import React, { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import Layout from "../../components/Community/Layout"
import { space, color, layout, typography, border } from "styled-system"
import styled from "styled-components"
import { Formik, Form, Field } from "formik"
import Upload from "../../components/Community/upload"
import { urlFor } from "../../sanity"

import { Flex, Image, Title, Button, Label } from "../../components/system"

const EditProfile = () => {
  const { user, getAccessTokenSilently } = useAuth0()
  const [profile, setProfile] = useState()
  const [status, setStatus] = useState("")
  const [imageLoading, setImageLoading] = useState(false)
  const [avatar, setAvatar] = useState(null)

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.GATSBY_AUTH0_DOMAIN

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        })

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })

        const { user_metadata } = await metadataResponse.json()

        setProfile(user_metadata)
      } catch (e) {
        alert(e.message)
      }
    }

    getUserMetadata()
  }, [getAccessTokenSilently, user])

  const updateProfile = async values => {
    const { bio, link, location } = values

    const domain = process.env.GATSBY_AUTH0_DOMAIN

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "update:users",
      })

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`

      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "PATCH",
        body: JSON.stringify({
          user_metadata: {
            bio: bio,
            link: link,
            location: location,
            avatar: avatar.url,
          },
        }),
      })

      const { user_metadata } = await metadataResponse.json()

      await fetch("/.netlify/functions/updateProfile", {
        method: "POST",
        body: JSON.stringify({
          id: profile.id,
          bio: bio,
          link: link,
          location: location,
          avatar: avatar._id,
        }),
      })
        .then(res => res.json())
        .then(data => setStatus("updated"))
        .catch(error => setStatus("error"))
    } catch (e) {
      setStatus("error")
    }
  }

  const uploadImage = async e => {
    if (e.target.value.length <= 0) return
    // const fileName = e.target.files[0].name
    const file = e.target.files[0]
    // const id = e.target.id

    try {
      setImageLoading(true)
      let response = await fetch(
        `https://${process.env.GATSBY_SANITY_PROJECT_ID}.api.sanity.io/v1/assets/images/${process.env.GATSBY_SANITY_DATASET}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${process.env.GATSBY_SANITY_TOKEN}`,
          },
          body: file,
        }
      )

      await response
        .json()
        .then(data => setAvatar(data.document))
        .then(() => setImageLoading(false))
    } catch (error) {
      console.log(error)
    }
  }

  if (!user) {
    return null
  }

  if (!profile) {
    return null
  }

  return (
    <Layout>
      <Flex flexDirection="column" mb={[4, 5]}>
        <Title fontSize={[5, 6]} color="primary">
          Edit Profile
        </Title>
      </Flex>
      <Flex>
        <Formik
          initialValues={{
            name: profile.name,
            bio: profile.bio,
            link: profile.link,
            location: profile.location,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              updateProfile(values)
              setSubmitting(false)
            }, 400)
          }}
        >
          {({ isSubmitting }) => (
            <StyledForm width={["90%", "70%", "40%"]} mb={6}>
              <Flex flexDirection="column" alignItems="flex-start" mb={4}>
                <Title fontSize={4} mb={2}>
                  Avatar
                </Title>
                {avatar ? (
                  <Image
                    src={urlFor(avatar._id)}
                    alt=""
                    width={["80vw", "20vw"]}
                    height={["80vw", "20vw"]}
                    borderRadius={5}
                    style={{ objectFit: "cover" }}
                  />
                ) : (
                  <Upload
                    type="image"
                    accept="image/*"
                    loading={imageLoading}
                    id="image"
                    name="imageUpload"
                    placeholder="upload image"
                    onChange={e => uploadImage(e)}
                  />
                )}
              </Flex>

              <Flex flexDirection="column" alignItems="flex-start" mb={4}>
                <Title fontSize={4} mb={2}>
                  Bio
                </Title>
                <TextInput
                  type="text"
                  component="textarea"
                  rows="6"
                  p={3}
                  name="bio"
                  placeholder="Tell us a little bit about yourself. We would love to know where are you from, what are your goals and inspiration is, what are your skills and expertise, what are your achievements."
                  autoComplete="off"
                />
              </Flex>

              <Flex flexDirection="column" alignItems="flex-start" mb={4}>
                <Title fontSize={4} mb={2}>
                  Location
                </Title>
                <TextInput
                  type="text"
                  p={3}
                  name="location"
                  placeholder="Nigeria"
                  autoComplete="off"
                />
              </Flex>

              <Flex flexDirection="column" alignItems="flex-start" mb={4}>
                <Title fontSize={4} mb={2}>
                  Website
                </Title>
                <TextInput
                  type="text"
                  p={3}
                  name="link"
                  placeholder="https://ilana.uk"
                  autoComplete="off"
                />
              </Flex>

              <Button
                bg="black"
                color="white"
                hbg="#404040"
                hcolor="white"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
              {status === "updated" ? (
                <Label ml={3} down color="green">
                  Profile Updated!
                </Label>
              ) : status === "error" ? (
                <Label ml={3} down color="red">
                  Something went wrong!
                </Label>
              ) : null}
            </StyledForm>
          )}
        </Formik>
      </Flex>
    </Layout>
  )
}

export default EditProfile

const TextInput = styled(Field)(
  {
    color: "black",
    fontFamily: "Manrope",
    border: "2px solid #cecece",
    borderRadius: 5,
    width: "100%",
    "&::placeholder": {
      color: "#cecece",
    },
  },
  space,
  color,
  layout,
  typography,
  border
)

const StyledForm = styled(Form)({}, space, color, layout, typography, border)
